import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
const text =
  'The 2023 Sample Sale is open to Victoria’s Secret and Company home office, distribution center and customer care center associates only. Participation is voluntary. Contractors, former associates, seasonal associates and temporary labor are NOT eligible to participate. The opportunity to purchase Victoria’s Secret and Company’s merchandise at deeply discounted prices hinges on respect of our values and the common-sense expectations that govern our work. You are not permitted to resell merchandise purchased at the Sample Sale to anyone anywhere, including flea markets, auction websites and rummage sales. You may not return merchandise purchased at the Sample Sale to retail stores. You may not give or sell your ID or discount card to anyone else for admission to the Sample Sale. You must shop for merchandise in person, as there is no shipping option. All purchases are final, and no refunds will be issued. Violation of these terms would be considered a violation of the Code of Conduct and may result in legal or disciplinary action, including termination of employment.';
const ComplianceTerms = () => {
  return (
    <Fragment>
      <Typography>
        {text}{' '}
      </Typography>
    </Fragment>
  );
};
export default ComplianceTerms;